import styled, { css } from 'styled-components'
import { Row } from 'react-styled-flexboxgrid'
import config, { device } from '../styles/config'

const ContentBlock = styled(Row)`
  margin: 0 auto;
  margin-bottom: 13.88rem;

  @media ${ device.sm } {
    margin-bottom: 6.9rem;
  }

  ${ ({ fullWidth }) => {
    if (fullWidth) {
      return css`
        > div {
          padding-left: 0;
          padding-right: 0;
        }
      `
    } else {
      return css`
          max-width: ${ config.pageWidth };
          width: 100%;
      `
    }
  } }
`

export default ContentBlock
