import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Col } from 'react-styled-flexboxgrid'
import ContentBlock from './ContentBlock'
import { device } from '../styles/config'

const Container = styled(ContentBlock)`
  font-size: 1.61rem;
  line-height: 1.414;
  position: relative;
  z-index: 0;

  a {
    text-decoration: underline;
  }

  ${ props => props.columns && css`
    ${ Col } {
      columns: 2;
      column-gap: 2.775rem;
      font-size: 0.77rem;
      line-height: 1.33;
      font-family: 'GT America';

      @media ${ device.sm } {
        columns: initial;
        column-gap: initial;
        font-size: 1.15rem;
      }

      p {
        padding-bottom: 1rem;
      }
    }
  ` }
`

const TextBlock = ({ text, align }) => {
  return (
    <Container columns={align === 'small font - two columns'}>
      <Col
        {...align === 'left' && {
          sm: 4,
          xs: 8
        }}
        {...align === 'right' && {
          sm: 4,
          smOffset: 4,
          xs: 8
        }}
        {...align === 'full width' && {
          sm: 8,
          xs: 8
        }}
        {...align === 'small font - two columns' && {
          sm: 6,
          xs: 8
        }}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </Container>
  )
}

TextBlock.propTypes = {

}

export default TextBlock
