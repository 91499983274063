import PropTypes from 'prop-types'
import React, { useCallback, useState, useRef } from 'react'
import styled from 'styled-components'
import Draggable from 'react-draggable'
import throttle from 'lodash.throttle'

import config, { device } from '../styles/config'
import isMobile from '../utils/isMobile'
import { Row, Col } from 'react-styled-flexboxgrid'
import ContentBlock from './ContentBlock'

import cursorSvg from '../images/cursor.svg'
import cursorPng1x from '../images/cursor-1x.png'
import cursorPng2x from '../images/cursor-2x.png'
import ExpandSvg from '../images/expand.svg'

const Container = styled.div`
  font-size: 1.27rem;
  line-height: 1.30;
  font-family: 'GT America';
  padding: ${ props => props.expanded && '2rem' };
  background-color: ${ props => props.color };
  color: #000;
  width: ${ props => props.expanded && '420px' };
  position: fixed;
  right: 5%;
  top: 18%;
  z-index: 0;
  opacity: var(--descriptionOpacity);
  overflow: hidden;
  height: ${ props => !props.expanded && '40px' };
  cursor: grab;
  
  /* cursor: url(${ cursorPng1x }) 0 0, grab;
  cursor: url('${ cursorSvg }') 0 0, grab;
  cursor: -webkit-image-set(url(${ cursorPng1x }) 1x, url(${ cursorPng2x }) 2x) 0 0, grab; */


  @media ${ device.sm } {
    display: none;
    cursor: initial;
  }

  &:hover {
    z-index: 999;
  }

  a {
    text-decoration: underline;
  }
`

const DraggableContainer = styled.div`
  display: block;

  @media ${ device.sm } {
    display: none;
  }
`

const DescriptionContainer = styled(ContentBlock)`
  display: none;

  a {
    text-decoration: underline;
  }

  @media ${ device.sm } {
    display: flex;

    > div {
      padding-left: ${ config.pagePaddingMobile };
      padding-right: ${ config.pagePaddingMobile };
    }
  }
`

const Description = styled.div`
  white-space: pre-wrap;

  font-size:0.77rem;
  line-height: 1.3;

  @media ${ device.sm } {
    margin-top: 0;
    font-size: 1.83rem;
    line-height: 1.27;
  }
`

const DescriptionHeader = styled.div`
  margin-bottom: 1.2rem;
  font-size: 1rem;
  line-height: 1.33;
  white-space: pre;
`

const ExpandTopBar = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 2rem;
  z-index: 999999;
`

const ExpandButton = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  width: 12px;
  height: 10px;
  background-image: url(${ ExpandSvg });
  background-size: cover;
  background-position: center;
  transform: rotate(${ props => props.expanded ? '0deg' : '45deg' });
`

const CollapsedText = styled.div`
  font-size: 18px;
  padding-right: 40px;
  padding-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 93%;
  user-select: none;
`

const ProjectDescription = ({
  name,
  projectType,
  location,
  year,
  description,
  miscellaneous,
  color
}) => {
  const [expanded, setExpanded] = useState(true)
  const draggingActive = useRef(false)

  const handleDrag = useCallback(throttle(() => {
    if (!draggingActive.current) {
      draggingActive.current = true
    }
  }, 250), [])

  const handleDragStop = useCallback(() => {
    setTimeout(() => {
      draggingActive.current = false
    }, 50)
  }, [])

  const handleExpand = useCallback(() => {
    !draggingActive.current && setExpanded(prev => !prev)
  }, [])

  return (
    <>
      <DescriptionContainer>
        <Col xs={8}>
          {description && <Description dangerouslySetInnerHTML={{ __html: description }}/>}
        </Col>
      </DescriptionContainer>
      <DraggableContainer>
        <Draggable
          defaultPosition={{ x: 0, y: 0 }}
          scale={1}
          disabled={isMobile()}
          onDrag={handleDrag}
          onStop={handleDragStop}
        >
          <Container expanded={expanded} color={color} className="fixed-position-description">
            <ExpandTopBar onClick={handleExpand}/>
            <ExpandButton expanded={expanded}/>
            {expanded && miscellaneous && <DescriptionHeader>{miscellaneous}</DescriptionHeader>}
            {expanded && description && <Description dangerouslySetInnerHTML={{ __html: description }}/>}
            {!expanded && <CollapsedText>Info</CollapsedText>}
          </Container>
        </Draggable>
      </DraggableContainer>
    </>
  )
}

// ProjectDescription.propTypes = {
//   image: PropTypes.object,
//   name: PropTypes.string,
//   projectType: PropTypes.string,
//   location: PropTypes.string,
//   year: PropTypes.number,
//   miscellaneous: PropTypes.string,
// }

export default ProjectDescription
