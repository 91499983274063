import React, { Fragment } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { Grid, Col, Row } from 'react-styled-flexboxgrid'
import ImageBlock from '../components/ImageBlock'
import TextBlock from '../components/TextBlock'
import VideoBlock from '../components/VideoBlock'
import QuoteBlock from '../components/QuoteBlock'
import ProjectList from '../components/ProjectList'
import ProjectDescription from '../components/ProjectDescription'
import config, { device } from '../styles/config'

const Container = styled.div`
  margin-top: 200px;

  @media ${ device.sm } {
    margin-top: 0;
  }
`

const Header = styled.h1`
  display: none;
  margin: 0 auto;
  width: 100%;
  max-width: ${ config.pageWidth };
  padding-left: ${ config.pagePadding };
  padding-right: ${ config.pagePadding };
  text-align: center;
  margin-top: 150px;
  text-transform: uppercase;
  margin-bottom: 50px;

  @media ${ device.sm } {
    display: block;
    padding-left: ${ config.pagePaddingMobile };
    padding-right: ${ config.pagePaddingMobile };
    margin-top: 45px;
    margin-bottom: 15px;
  }
`

const Details = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: ${ config.pageWidth };
  padding-left: ${ config.pagePadding };
  padding-right: ${ config.pagePadding };
  align-items: center;
  flex-direction: column;
  font-size: 2.22rem;
  line-height: 1.25;
  margin-bottom: 10rem;
  margin-top: 150px;
  display: none;
  font-family: 'GT America';

  

  @media ${ device.sm } {
    display: flex;
    padding-left: ${ config.pagePaddingMobile };
    padding-right: ${ config.pagePaddingMobile };
    margin-top: 0;
    margin-bottom: 70px;
  }
`

const Miscellaneous = styled.div`
  white-space: pre-wrap;
  text-align: center;
`

const ProjectPage = ({
  data: {
    datoCmsProject: {
      name,
      projectType,
      location,
      description,
      year,
      miscellaneous,
      contentBlocks,
      color
    }
  }
}) => {
  return (
    <>
      <Container>
        {/* <Header>{name}</Header> */}
        <Details>
          {/* <div>
            {projectType &&
            <span>{projectType}, </span>
            }
            <span>{location}</span> <span>{year}</span>
          </div> */}
          <div>
            <Miscellaneous>{miscellaneous}</Miscellaneous>
          </div>
        </Details>
        <ProjectDescription
          name={name}
          location={location}
          year={year}
          projectType={projectType}
          miscellaneous={miscellaneous}
          description={description}
          color={color.hex}
        />
        {contentBlocks.map((contentBlock, index) => {
          let content

          if (contentBlock.text) {
            content = <TextBlock key={index} {...contentBlock}/>
          }

          if (contentBlock.video) {
            content = <VideoBlock key={index} {...contentBlock}/>
          }

          if (contentBlock.quote) {
            content = <QuoteBlock key={index} {...contentBlock}/>
          }

          if (contentBlock.image) {
            content = <ImageBlock key={index} {...contentBlock}/>
          }

          return content
        })}
        <ProjectList bottom color={color.hex}/>
      </Container>
    </>
  )
}

export const query = graphql`
  query($originalId: String!) {
    datoCmsProject(originalId: {eq: $originalId}) {
      name
      color {
        hex
      }
      projectType
      location
      year
      miscellaneous
      description
      contentBlocks {
        ... on DatoCmsImageBlock {
          image {
            fluid(maxWidth: 1400, imgixParams: {
              fm: "jpg",
              auto: "compress"
            }) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
          size
          index
          sideBySide
          sideImageSmall
          sideIndex
          hideOnMobile
          sideImage {
            fluid(maxWidth: 1400, imgixParams: {
              fm: "jpg",
              auto: "compress"
            }) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
        ... on DatoCmsTextBlock {
          id
          text
          align
        }
        ... on DatoCmsVideoBlock {
          video {
            width
            height
            providerUid
            provider
          }
          size
          align
          autoplay
          loop
          index
        }
        ... on DatoCmsQuoteBlock {
          quote
          backgroundColor {
            rgb
          }
        }
      }
    }
  }
`

export default ProjectPage
