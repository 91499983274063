import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import styled from 'styled-components'

import useWindowHeight from '../hooks/useWindowHeight'
import config, { device } from '../styles/config'

const Container = styled.div`
  height: ${ props => props.windowHeight ? `${ props.windowHeight }px` : '100vh' };
  margin-bottom: 13.88rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${ ({ backgroundColor }) => backgroundColor };
  color: var(--color-text);
  font-size: ${ ({ small }) => small ? '3.88rem' : '2.77rem' };
  line-height: 1.2;
  padding: ${ config.pagePadding };
  z-index: 1;

  @media ${ device.sm } {
    font-size: 2rem;
    margin-bottom:  6.9rem;
  }

  p:empty {
    height: 1rem;
  }
`

const QuoteBlock = ({ quote, backgroundColor }) => {
  const windowHeight = useWindowHeight()

  const small = quote.length < 220

  return (
    <Container
      windowHeight={windowHeight}
      backgroundColor={backgroundColor.rgb}
      small={small}
    >
      <div dangerouslySetInnerHTML={{ __html: quote }}></div>
    </Container>
  )
}

QuoteBlock.propTypes = {

}

export default QuoteBlock
