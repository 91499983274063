import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import { Col } from 'react-styled-flexboxgrid'
import ContentBlock from './ContentBlock'
import styled, { css } from 'styled-components'
import config, { device } from '../styles/config'

const Container = styled(ContentBlock)`
  @media ${ device.sm } {
    ${ props => props.hideOnMobile && css`
      display: none;
    ` }

    > div {
      margin-bottom: 13.88rem;

      @media ${ device.sm } {
        margin-bottom: 6.9rem;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

const Column = styled(Col)`
  position: relative;

  @media ${ device.sm } {
    padding-left: ${ config.pagePaddingMobile };
    padding-right: ${ config.pagePaddingMobile };
  }
`

const Image = styled(GatsbyImage)`
  z-index: 1;
  overflow: visible !important;
  
  img {
    image-rendering: -moz-crisp-edges;         /* Firefox */
    image-rendering:   -o-crisp-edges;         /* Opera */
    image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
    /* image-rendering: crisp-edges; */
    -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
  }

  &:after {
    content: ${ props => props.index && `'${ props.index.toString() }'` };
    display: ${ props => props.index ? 'block' : 'none' };
    position: absolute;
    bottom: -20px;
    line-height: 20px;
    color: var(--color-text);
    left: 0;

    font-size: 0.77rem;
    line-height: 1.33;
    font-family: 'GT America';

    @media ${ device.sm } {
      font-size: 1.15rem;
    }
  }
`

const ImageBlock = ({
  sideBySide,
  size,
  image,
  sideImage,
  sideImageSmall,
  index,
  sideIndex,
  hideOnMobile
}) => {
  return (
    <Container
      hideOnMobile={hideOnMobile}
      {...!sideBySide && {
        center: 'sm'
      }}
      {...!sideBySide && size === 'full width' && {
        fullWidth: true
      }}
      {...sideBySide && {
        between: 'sm'
      }}
    >
      {!sideBySide &&
        <Column sm={sizeToColumn(size, sideBySide)} xs={8}>
          <Image fluid={image.fluid} index={index}/>
        </Column>
      }
      {sideBySide &&
        <>
          <Column sm={sizeToColumn(size, sideBySide)} xs={8}>
            <Image fluid={{
              ...image.fluid,
              sizes: image.fluid.sizes.replace(/1200px/g, '600px')
            }} index={index}/>
          </Column>
          {sideImage &&
            <Column sm={sideImageSmall ? 3 : 4} xs={8}>
              <Image fluid={sideImage.fluid} index={sideIndex}/>
            </Column>
          }
        </>
      }
    </Container>
  )
}

function sizeToColumn (size, sideBySide) {
  if (sideBySide) {
    switch (size) {
    case 'small':
      return 3

    default:
      return 4
    }
  }

  switch (size) {
  case 'small':
    return 4

  case 'medium':
    return 6

  case 'large':
    return 8

  case 'full width':
    return 8

  default:
    return 4
  }
}

ImageBlock.propTypes = {

}

export default ImageBlock
