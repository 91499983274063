import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import isMobile from '../utils/isMobile'
import { Col } from 'react-styled-flexboxgrid'
import ContentBlock from './ContentBlock'
import config, { device } from '../styles/config'
import PlaySvg from '../images/play.svg'

import 'plyr/dist/plyr.css'

class VideoBlock extends Component {
  constructor (props) {
    super(props)

    // this.state = {
    //   loading: true,
    //   muted: true,
    //   posterSrc: null
    // }

    this.video = React.createRef()
    this.plyr = null
  }

  componentDidMount () {
    const { autoplay, loop } = this.props

    console.log('autoplay', autoplay)
    console.log('loop', loop)

    // if ((!autoplay || youtube) && window !== 'undefined') {
    this.plyr = new window.Plyr(`#player-${ this.props.video.providerUid }`, {
      hideControls: true,
      controls: !autoplay ? ['play-large', 'progress', 'fullscreen'] : false,
      clickToPlay: !autoplay,
      autoplay: autoplay,
      playsinline: true,
      muted: autoplay,
      blankVideo: 'https://cdn.plyr.io/static/blank.mp4',
      loop: {
        active: loop
      },
      vimeo: {
        background: autoplay
      }
    })

    this.plyr.on('ended', player => {
      player.detail.plyr.stop()
    })
    // } else {
    //   fetch(`https://onelineplayer.glitch.me/vimeo?url=https://vimeo.com/${ this.props.video.providerUid }`)
    //     .then(response => {
    //       return response.json()
    //     })
    //     .then(data => {
    //       if (!this.video.current) {
    //         return
    //       }

    //       this.setState({
    //         posterSrc: data.poster
    //       })

    //       this.video.current.muted = true
    //       this.video.current.setAttribute('muted', '')
    //       this.video.current.src = getVideoSource(data.video, isMobile())
    //       this.video.current.addEventListener('timeupdate', this.onVideoLoaded)

    //       this.video.current.play()
    //     })
    // }
  }

  // componentWillUnmount () {
  //   if (!this.props.autoplay) {
  //     this.plyr && this.plyr.destroy()
  //   } else {
  //     this.video.current.pause()
  //     this.video.current.removeEventListener('timeupdate', this.onVideoLoaded)
  //     this.video.current.removeEventListener('timeupdate', this.onProgress)
  //   }
  // }

  // onVideoLoaded = () => {
  //   if (this.video.current.currentTime > 0) {
  //     this.video.current.removeEventListener('timeupdate', this.onVideoLoaded)
  //     this.video.current.addEventListener('timeupdate', this.onProgress)
  //     this.setState({
  //       loading: false
  //     })
  //   }
  // }

  render () {
    const { video: { width, height, providerUid, provider }, size, autoplay, align, loop, index } = this.props

    // const plyr = !autoplay || provider === 'youtube'
    // const plyr = !autoplay || provider === 'youtube'

    return (
      <ContentBlock
        {...align === 'left' && {
          start: 'sm'
        }}
        {...align === 'right' && {
          end: 'sm'
        }}
        {...align === 'center' && {
          center: 'sm'
        }}
      >
        <Column sm={sizeToColumn(size)} xs={8}>
          <VideoContainer width={width} height={height} index={index}>
            <div
              id={`player-${ providerUid }`}
              data-plyr-provider={provider}
              data-plyr-embed-id={providerUid}

            />
          </VideoContainer>
        </Column>
      </ContentBlock>
    )
  }
}

const VideoContainer = styled.div`
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: ${ ({ width, height }) => `${ height / width * 100 }%` };
  position: relative;

  &:after {
    content: ${ props => props.index && `'${ props.index.toString() }'` };
    display: ${ props => props.index ? 'block' : 'none' };
    position: absolute;
    bottom: -20px;
    line-height: 20px;
    color: var(--color-text);
    left: 0;
    font-size: 16px;
  }

  .plyr {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .plyr__control svg {
    transform: scale(0.8);
  }

  .plyr__control--overlaid {
    border-radius: 0;
    background: none;
    background-image: url(${ PlaySvg });
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px;

    &:hover {
      background: none !important;
      background-image: url(${ PlaySvg }) !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      color: initial !important;
    }

    .plyr__poster {
      /* left: -1px;
      right: -1px;
      top: -1px;
      bottom: -1px;
      height: initial;
      width: initial;
      background-size: cover; */
    }

    svg {
      display: none;
    }
  }

  .plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover,
  .plyr--video .plyr__control[aria-expanded=true] {
    background: initial;
    color: initial;
  }

  .plyr--full-ui input[type=range]::-webkit-slider-thumb {
    background: #fff;
    border: 0;
    border-radius: 100%;
    box-shadow: 0 1px 1px rgba(0,0,0,.15), 0 0 0 1px rgba(47,52,61,.2);
    box-sizing: border-box;
    height: 10px;
    position: relative;
    transition: all .2s ease;
    width: 10px;
    -webkit-appearance: none;
    margin-top: -2px;
  }

  .plyr--full-ui input[type=range] {
    color: #fff;
  }
`

const Column = styled(Col)`
  @media ${ device.sm } {
    padding-left: ${ config.pagePaddingMobile };
    padding-right: ${ config.pagePaddingMobile };
  }
`

const PlaceholderImage = styled.img`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
`

const Video = styled.video`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

function sizeToColumn (size) {
  switch (size) {
  case 'small':
    return 4

  case 'medium':
    return 6

  case 'large':
    return 8

  case 'full width':
    return 8

  default:
    return 4
  }
}

function getVideoSource (sources, isMobile) {
  const sortedResolutions = Object.keys(sources).map(item => parseInt(item.slice(0, -1))).sort((a, b) => b - a)

  const low = sortedResolutions[0]
  const high = sortedResolutions[sortedResolutions.length - 1]

  if (isMobile) {
    return sources['360p'] || sources['240p'] || sources[`${ low }p`]
  } else {
    return sources['720p'] || sources[`${ high }p`]
  }
}

VideoBlock.propTypes = {
  providerUid: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

export default VideoBlock
